import React, { useState } from 'react';
import { RadioGroup } from '@headlessui/react';

const paymentMethods = [
  { name: 'Pay Online', value: 'online' },
  { name: 'Pay Using Cash', value: 'cash' },
];

const SelectPaymentMethodButton = ({ handleChange, name, label, value, rules, fieldError, dynamicFieldErrors }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(value);

  const handlePaymentChange = (paymentMethod) => {
    handleChange(paymentMethod);
    setSelectedPaymentMethod(paymentMethod);
  };

  return (
    <div className="w-full py-4">
      <div className="mx-auto w-full">
        <label htmlFor={name} className={`flex items-center text-sm font-medium text-gray-700 ${!label && 'sr-only'}`}>
          {rules.required && <span className="text-red-500 mr-1">*</span>}
          {(fieldError || dynamicFieldErrors) && <div id="rule-error"></div>}
          {label}
        </label>
        <RadioGroup value={selectedPaymentMethod} onChange={handlePaymentChange}>
          <RadioGroup.Label className="sr-only">Payment Method</RadioGroup.Label>
          <div className="space-y-2">
            {paymentMethods.map((type) => (
              <RadioGroup.Option
                key={type.value}
                value={type.value}
                className={({ active, checked }) =>
                  `${checked ? 'bg-primary-500 text-white border-primary-500' : 'bg-white border'}
                    group relative border rounded-md py-2 px-3 flex items-center justify-center text-sm font-medium uppercase shadow-sm cursor-pointer`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label as="p" className={`font-medium ${checked ? 'text-white' : 'text-gray-900'}`}>
                            {type.name}
                          </RadioGroup.Label>
                        </div>
                      </div>
                      {checked && (
                        <div className="shrink-0 text-white">
                          <CheckIcon className="h-6 w-6" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
};

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path d="M7 13l3 3 7-7" stroke="#fff" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default SelectPaymentMethodButton;
