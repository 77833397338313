import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Badge, Calendar, Select, Radio } from 'antd';

import Table from '../../../components/Table';
import { showToast } from '../../../store/slices/components.slice';
import OrderInfo from './OrderInfo';
import { listOrders, listScheduleClose } from '../../../store/slices/vendor.slice';
import { classNames, statusFormatter, monthToText } from '../../../utils';
import Tooltip from '../../../components/Tooltip';

import 'antd/dist/antd.css';
const StoreOrders = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const groupDate = searchParams.get('groupDate');
  const { vendorApi } = useSelector((state) => state);

  const dispatch = useDispatch();
  const [calendarData, setCalendarData] = useState([]);
  const [scheduleCloseData, setScheduleCloseData] = useState({});
  const [calendarType, setCalendarType] = useState('month');
  const [selectedMonth, setSelectedMonth] = useState(moment().format('YYYY-MM'));
  const [selectedDate, setSelectedDate] = useState(moment());
  const [tableData, setTableData] = useState([]);
  const [displaySingleDate, setDisplaySingleDate] = useState(false);
  const [searchOrder, setSearchOrder] = useState('');

  const [tooltipScheduleCloseButton, setTooltipScheduleCloseButton] = useState(true);

  useEffect(() => {
    const query = {
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD'),
    };
    if (groupDate && groupDate.match(/^\d{4}\-(0[1-9]|1[012])$/)) {
      setSelectedMonth(groupDate);
      setSelectedDate(moment().format('YYYY-MM') === groupDate ? moment() : moment(`${groupDate}-01`));
      query.startDate = moment(`${groupDate}-01`).format('YYYY-MM-DD');
      query.endDate = moment(`${groupDate}-01`).endOf('month').format('YYYY-MM-DD');
    }
    handleFilterOrderByMonth(query);
  }, []);

  const clearCalenderScheduleClose = () => {
    var td = document.getElementsByTagName('td');
    for (var i = 0; i < td.length; i++) {
      td[i].className = `${td[i].getAttribute('class').replace('close-box-bg', '')}`;
    }
  };

  const updateQueryString = (nextGroupDate) => {
    history.replace({ pathname: location.pathname, search: `?groupDate=${nextGroupDate}` });

    const queryScheduleClose = {
      start: moment(`${nextGroupDate}-01`).subtract(1, 'month').format('YYYY-MM'),
      end: moment(`${nextGroupDate}-01`).endOf('month').add(1, 'month').format('YYYY-MM'),
    };
    getScheduleClose(queryScheduleClose);
  };

  const getScheduleClose = (query) => {
    clearCalenderScheduleClose();
    dispatch(listScheduleClose(query))
      .unwrap()
      .then((result) => {
        const { data } = result;
        const closeDates = {};
        data?.scheduleCloses?.forEach((group) => {
          group.dates.forEach((date) => {
            closeDates[date] = true;
          });
        });
        setScheduleCloseData(closeDates);
      })
      .catch(({ error, message }) => {
        dispatch(showToast({ show: true, type: 'error', message: error || message }));
      });
  };

  const handleFilterOrderByDate = (dataDate, todaySelect) => {
    setSelectedDate(moment(dataDate.startDate));
    let dateRange = {
      startDate: moment(dataDate.startDate).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
      endDate: moment(dataDate.endDate).endOf('month').add(14, 'days').format('YYYY-MM-DD'),
    };

    if (moment(dataDate.startDate).diff(dataDate.endDate, 'days') === 0) {
      setDisplaySingleDate(true);
      const ordersDisplay = calendarData.filter((order) => {
        for (var m = moment(dataDate.startDate); m.diff(dataDate.endDate, 'days') <= 0; m.add(1, 'days')) {
          const date = m.format('YYYY-MM-DD');
          if (order.deliverDate === date) {
            return true;
          }
        }
        return false;
      });
      setTableData(ordersDisplay);
    }
    if (displaySingleDate && selectedDate.format('YYYY-MM-DD') === dataDate.startDate) {
      setDisplaySingleDate(false);
      resetTable();
    }

    if (selectedMonth === moment(dataDate.startDate).format('YYYY-MM')) {
      return;
    }

    dispatch(listOrders(dateRange))
      .unwrap()
      .then((result) => {
        const { data } = result;
        const orders = [...data?.orders].reverse();

        setCalendarData(orders);

        if (todaySelect) {
          const ordersDisplay = orders.filter((order) => {
            return order.deliverDate === moment().format('YYYY-MM-DD');
          });
          setTableData(ordersDisplay);
        }
      })
      .catch(({ error, message }) => {
        dispatch(showToast({ show: true, type: 'error', message: error || message }));
      });

    setSelectedMonth(moment(dataDate.startDate).format('YYYY-MM'));
    updateQueryString(moment(dataDate.startDate).format('YYYY-MM'));
  };

  const handleFilterOrderByMonth = (query) => {
    setDisplaySingleDate(false);
    const dateRange = {
      startDate: moment(query.startDate).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
      endDate: moment(query.endDate).endOf('month').add(14, 'days').format('YYYY-MM-DD'),
    };
    dispatch(listOrders(dateRange))
      .unwrap()
      .then((result) => {
        const { data } = result;
        const orders = [...data?.orders].reverse();
        setCalendarData(orders);

        const ordersDisplay = orders.filter((order) => {
          return moment(order.deliverDate).format('YYYY-MM') === moment(query.startDate).format('YYYY-MM');
        });
        setTableData(ordersDisplay);
      })
      .catch(({ error, message }) => {
        dispatch(showToast({ show: true, type: 'error', message: error || message }));
      });
    setSelectedMonth(moment(query.startDate).format('YYYY-MM'));
    updateQueryString(moment(query.startDate).format('YYYY-MM'));
  };

  const getToday = () => {
    const query = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    };

    handleFilterOrderByDate(query, true);
    setSelectedDate(moment());
  };

  const dateCellRender = (value) => {
    let arr = [];

    calendarData?.forEach((data) => {
      if (moment(value).format('YYYY-MM-DD') === data.deliverDate) {
        arr.push(data);
      }
    });

    let closeHighlight = '';
    if (scheduleCloseData[value.format('YYYY-MM-DD')]) {
      closeHighlight = 'bg-red-100';
    }

    let selectedBox = '';
    if (displaySingleDate && selectedDate.format('YYYY-MM-DD') === value.format('YYYY-MM-DD')) {
      selectedBox = 'border border-primary-300 border-x-4 border-y-4 rounded';
    }

    return (
      <>
        <div className={`${selectedBox} ${closeHighlight}`}>
          <div className="ant-picker-cell-inner">
            {value.date()}
            <div className="flex items-center justify-center w-full">
              <span className={classNames(arr.length > 0 ? 'text-primary-500 font-medium' : 'text-gray-400', 'text-xs')}>
                {arr.length} {arr.length > 1 ? 'orders' : 'order'}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const tableHeaders = [
    {
      title: 'Order ID',
      objPropKey: 'uid',
      render: (uid) => (
        <Link to={`/store/orders/${uid}`} className="text-sm font-medium text-primary-600 hover:text-primary-700">
          {uid}
        </Link>
      ),
    },
    {
      title: 'Customer',
      objPropKey: 'customer',
      render: (customer) => (
        <div>
          <p className="text-sm text-500">{customer?.name}</p>
          <p className="text-sm text-500">{customer?.email}</p>
          <p className="text-sm text-500">{customer?.phoneNumber}</p>
        </div>
      ),
    },
    {
      title: 'Status',
      objPropKey: 'status',
      render: (status) => (
        <p className="text-sm text-500" style={{ color: statusFormatter(status).color }}>
          {statusFormatter(status)?.text}
        </p>
      ),
    },
    {
      title: 'Payment Method',
      objPropKey: 'paymentMethod',
      render: (paymentMethod) => <span className="capitalize">{!!paymentMethod ? paymentMethod : '-'}</span>,
    },
    {
      title: 'Amount (RM)',
      objPropKey: 'totalPrice',
      render: (totalPrice) => <span>{totalPrice.toFixed(2)}</span>,
    },
    {
      title: 'Location City',
      objPropKey: 'city',
      render: (city, order) => <>{order?.selfPickup ? <span className="text-sm text-green-500 ">SELF PICKUP</span> : <span> {city}</span>}</>,
    },
    {
      title: 'Delivery On',
      objPropKey: 'deliverDate',
      render: (deliverDate, order) => <span>{moment(`${order?.deliverDate} ${order?.deliverTime}`).format('DD MMM YYYY - hh:mm A')}</span>,
    },
    {
      title: 'Pickup time',
      objPropKey: 'deliverTime',
      render: (deliverDate, order) => <span>{order?.pickupTimeExpectation && <span>{order.pickupTimeExpectation} +-</span>}</span>,
    },
  ];

  const resetTable = () => {
    const ordersDisplay = calendarData.filter((order) => {
      return moment(order.deliverDate).format('YYYY-MM') === selectedMonth;
    });
    setDisplaySingleDate(false);
    setTableData(ordersDisplay);
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="text-xl sm:text-2xl font-bold text-gray-900">Orders</h2>
      </div>

      {tooltipScheduleCloseButton && (
        <div
          class="grid grid-cols-2 sm:grid-cols-4"
          onClick={() => {
            setTooltipScheduleCloseButton(false);
          }}
        >
          <div class="flex justify-start items-center">
            <Tooltip from="top" bgColor="bg-amber-500 text-white" chevronColor="text-amber-500">
              Operation close/holidays
            </Tooltip>
          </div>
        </div>
      )}

      <div className="mt-4 border rounded-md overflow-hidden">
        <Calendar
          fullscreen={false}
          dateFullCellRender={dateCellRender}
          onSelect={(data) => {
            calendarType === 'year'
              ? handleFilterOrderByMonth({ startDate: moment(data).startOf('month').format('YYYY-MM-DD'), endDate: moment(data).endOf('month').format('YYYY-MM-DD') })
              : handleFilterOrderByDate({ startDate: moment(data).format('YYYY-MM-DD'), endDate: moment(data).format('YYYY-MM-DD') });
          }}
          value={selectedDate}
          headerRender={({ value, type, onChange, onTypeChange }) => {
            const start = 0;
            const end = 12;
            const monthOptions = [];

            const current = value.clone();
            const localeData = value.localeData();
            const months = [];
            for (let i = 0; i < 12; i++) {
              current.month(i);
              months.push(localeData.monthsShort(current));
            }

            for (let index = start; index < end; index++) {
              monthOptions.push(
                <Select.Option className="month-item" key={`${index}`}>
                  {months[index]}
                </Select.Option>,
              );
            }

            const month = value.month();

            const year = value.year();
            const options = [];
            for (let i = year - 10; i < year + 10; i += 1) {
              options.push(
                <Select.Option key={i} value={i} className="year-item">
                  {i}
                </Select.Option>,
              );
            }
            return (
              <div class="grid grid-cols-2 sm:grid-cols-4 gap-1 py-2 px-2">
                <div class="flex justify-start items-center">
                  <Link
                    to={`/store/schedule_close/${selectedMonth}`}
                    className="flex items-center text-primary-100 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 px-2 py-2 rounded-md text-sm font-medium"
                  >
                    Schedule Close
                  </Link>
                </div>
                <div class=" flex sm:justify-center justify-end items-center">
                  <span className="text-primary-500 hover:text-primary-400 ">
                    <b>
                      {value.format('MMMM')} {year}{' '}
                    </b>
                  </span>
                </div>
                <div class="flex sm:justify-center justify-start  items-center">
                  {selectedDate.format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD') && (
                    <button type="button" onClick={getToday} className=" text-primary-600 hover:text-primary-500 px-2">
                      Today
                    </button>
                  )}
                  {displaySingleDate && (
                    <button type="button" onClick={resetTable} className="flex items-center text-primary-600 hover:text-primary-500 focus:outline-none">
                      List by month
                    </button>
                  )}
                </div>
                {/* <div class="flex justify-center items-center">
                <Radio.Group
                      size="small"
                      onChange={(e) => {
                        onTypeChange(e.target.value);
                        setCalendarType(e.target.value);
                      }}
                      value={type}
                    >
                      <Radio.Button value="month">Month</Radio.Button>
                      <Radio.Button value="year">Year</Radio.Button>
                    </Radio.Group>
                </div> */}
                <div class=" flex justify-end items-center">
                  <Select
                    size="small"
                    dropdownMatchSelectWidth={false}
                    value={String(month)}
                    onChange={(selectedMonth) => {
                      const newValue = value.clone();
                      newValue.month(parseInt(selectedMonth, 10));
                      onChange(newValue);
                    }}
                  >
                    {monthOptions}
                  </Select>
                  <Select
                    size="small"
                    dropdownMatchSelectWidth={false}
                    className="my-year-select"
                    onChange={(newYear) => {
                      const now = value.clone().year(newYear);
                      onChange(now);
                    }}
                    value={String(year)}
                  >
                    {options}
                  </Select>
                </div>
              </div>
            );
          }}
        />
      </div>
      <div className="md:hidden mt-6 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">
            <svg className="w-5 h-5 sm:w-4 sm:h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </span>
        </div>
        <input
          onInput={(e) => setSearchOrder(e.target.value)}
          type="text"
          name="search-table"
          className="focus:ring-primary-600 focus:border-primary-600 block w-full pl-10 sm:pl-9 pr-16 text-sm border-gray-300 rounded-md"
          placeholder="Search"
        />
      </div>
      <div className="mt-6 flex flex-col">
        <div className="block md:hidden divide-y space-y-6">
          {tableData
            ?.filter((val) => {
              if (!searchOrder) {
                return true;
              }
              return (
                val.customer.name.toLowerCase().includes(searchOrder.toLowerCase()) ||
                val.customer.email.toLowerCase().includes(searchOrder.toLowerCase()) ||
                val.customer.phoneNumber.toLowerCase().includes(searchOrder.toLowerCase())
              );
            })
            .map((data, index) => (
              <div key={index} className="p-4 bg-white border rounded-lg shadow-sm">
                <p className="text-sm text-gray-500">
                  Delivery on: {moment(`${data?.deliverDate} ${data?.deliverTime}`).format('DD MMM YYYY')} {moment(`${data?.deliverDate} ${data?.deliverTime}`).format('hh:mm A')}
                </p>
                {data?.pickupTimeExpectation && <p className="text-sm text-gray-500">Pickup time: {data.pickupTimeExpectation} +-</p>}

                {data?.paymentMethod && <p className="text-sm text-gray-500">Payment method: <span className="capitalize">{data.paymentMethod}</span></p>}

                <p className="text-sm text-500" style={{ color: statusFormatter(data?.status).color }}>
                  {statusFormatter(data?.status)?.text}
                </p>
                <Link to={`/store/orders/${data?.uid}`} className="mt-1 flex items-center font-medium">
                  <h3 className="mr-4 text-amber-600">{data?.uid}</h3>
                  <span className="ml-auto text-right flex-shrink-0 text-lg">RM {data?.totalPrice?.toFixed(2)}</span>
                </Link>
                <div>
                  <div className="text-xs">{data?.customer?.name}</div>
                  <div className="text-xs">
                    {data?.customer?.phoneNumber} {data?.customer?.email}
                  </div>
                </div>
                <div className="mt-1">
                  {data?.selfPickup ? <span className="text-sm text-green-500 ">SELF PICKUP</span> : <span className="mt-1 text-sm text-gray-500"> {data?.city}</span>}
                </div>
                {/* <p className="mt-1 text-sm text-gray-500">{data?.city}</p> */}
              </div>
            ))}
        </div>
        <div className="hidden md:block">
          <Table dataInput={tableData} headers={tableHeaders} pagination={{ limit: 10, pageNeighbours: 1 }} loading={vendorApi.loading}>
            <div className="flex flex-col items-center justify-center py-10 bg-white w-full text-gray-600">
              <svg className="w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z"></path>
                <path
                  fillRule="evenodd"
                  d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <h3 className="mt-2 font-medium">No result found</h3>
            </div>
          </Table>
        </div>
      </div>
    </>
  );
};

StoreOrders.Info = OrderInfo;
export default StoreOrders;
