import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import StoreQuoteCreate from './Create';

import Table from '../../../components/Table';

import { listQuote } from '../../../store/slices/vendor.slice';
import { showToast } from '../../../store/slices/components.slice';
import moment from 'moment';
import { Form, Input } from '../../../components/Form';
import StoreQuoteDetail from './Detail';

const StoreQuote = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const { vendorApi } = useSelector((state) => state);
  const [selectDate, setSelectDate] = useState(moment(new Date()).format('yyyy-MM-DD'));
  const [placeholder, setPlaceHolder] = useState(moment(new Date()).format('yyyy-MM-DD'));
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleListQuotes();
  }, []);

  const handleListQuotes = (data) => {
    dispatch(listQuote(data || selectDate))
      .unwrap()
      .then((result) => {
        const listQuote = result?.data?.quotes.map((object) => ({ ...object }));
        listQuote.sort((a, b) => new Date(`${b.date} ${b.time}`).getTime() - new Date(`${a.date} ${a.time}`).getTime());
        const dataListQuote = listQuote.map((item) => {
          item.paymentMethod = !!item.paymentMethod ? item.paymentMethod : '-';
          item.groupStatus = {
            isExpired: item.isExpired,
            orderUid: item.orderUid,
          };
          return item;
        });

        setTableData(dataListQuote);
      })
      .catch(({ error, message }) => {
        dispatch(showToast({ show: true, type: 'error', message: error || message }));
      });
  };

  const handleDateChange = (data) => {
    setSelectDate(data);
    handleListQuotes(data);
  };

  const tableHeaders = [
    {
      title: 'Quote ID',
      objPropKey: 'uid',
      render: (uid) => (
        <div className="flex items-center">
          <Link to={`/store/quote/${uid}`}>
            <div className="text-sm font-medium text-primary-600 hover:text-primary-700">{uid}</div>
          </Link>
        </div>
      ),
    },
    {
      title: 'Status',
      objPropKey: 'groupStatus',
      render: (groupStatus) => (
        <div className="flex items-center">
          <div>
            <div
              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-md  
                  ${groupStatus?.orderUid ? 'bg-green-100 text-green-600' : groupStatus?.isExpired ? 'bg-gray-100 text-gray-400' : 'bg-primary-100 text-primary-600'}
                `}
            >
              {groupStatus?.orderUid ? 'ACCEPTED' : groupStatus?.isExpired ? 'EXPIRED' : 'PENDING'}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Date Created',
      objPropKey: 'date',
      render: (date) => (
        <div className="flex items-center">
          <div>
            <div className="text-sm font-medium text-gray-700">{date}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Time Created',
      objPropKey: 'time',
      render: (time) => (
        <div className="flex items-center">
          <div>
            <div className="text-sm font-medium text-gray-700">{time}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Customer Contact Number',
      objPropKey: 'customerContactNumber',
      render: (customerContactNumber) => (
        <div className="flex items-center">
          <div>
            <div className="text-sm font-medium text-gray-700">{customerContactNumber}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Payment Method',
      objPropKey: 'paymentMethod',
      render: (paymentMethod) => (
        <div className="flex items-center">
          <div>
            <div className="text-sm font-medium text-gray-700 capitalize">{paymentMethod}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Total Price (RM)',
      objPropKey: 'totalPrice',
      render: (totalPrice) => (
        <div className="flex items-center">
          <div>
            <div className="text-sm font-medium text-gray-700 ">{totalPrice}</div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="text-xl sm:text-2xl font-bold text-gray-900">Quote</h2>
        <Link
          to="/store/quote/create"
          className="flex items-center text-primary-100 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 px-3 py-2 rounded-md text-sm font-medium"
        >
          <svg className="-ml-1 mr-2 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
          </svg>
          New quote
        </Link>
      </div>

      <div className="mt-6 flex flex-col">
        <div className="w-full sm:w-2/6 mb-4">
          <Form>
            <Input.DatePickerV2
              name="date"
              label="Select Date"
              retrieveValue={(data) => handleDateChange(data)}
              disabled={vendorApi.loading}
              placeholder={placeholder === selectDate ? placeholder : 'yyyy-mm-dd'}
            />
          </Form>
        </div>
        {width >= 768 ? (
          <Table dataInput={tableData} headers={tableHeaders} pagination={{ limit: 10, pageNeighbours: 1 }} loading={vendorApi.loading}>
            <div className="flex flex-col items-center justify-center py-10 bg-white w-full text-gray-600">
              <svg className="w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z"></path>
                <path
                  fillRule="evenodd"
                  d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <h3 className="mt-2 font-medium">No result found</h3>
            </div>
          </Table>
        ) : (
          <div className="space-y-6 max-w-3xl mx-auto mb-20 min-h-screen">
            <div className="max-w-3xl mx-auto border rounded-lg bg-white shadow-md ">
              {tableData &&
                tableData.map((quote, index) => (
                  <div className="grid grid-cols-1 py-2 px-4 border-t">
                    <div className="grid-cols-2 col-span-1 ">
                      <div className="flex space-between">
                        <span className="font-medium text-sm mr-5">Quote</span>
                        <div
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-md  
                                  ${quote?.orderUid ? 'bg-green-100 text-green-600' : quote?.isExpired ? 'bg-gray-100 text-gray-400' : 'bg-primary-100 text-primary-600'}`}
                        >
                          {quote?.orderUid ? 'ACCEPTED' : quote?.isExpired ? 'EXPIRED' : 'PENDING'}
                        </div>
                      </div>
                      <Link to={`/store/quote/${quote?.uid}`}>
                        <div className="text-sm font-medium text-primary-600 hover:text-primary-700 break-all">{quote?.uid}</div>
                      </Link>
                      <div className="flex justify-between">
                        <div>
                          <p className="text-sm text-gray-500">{quote?.customerContactNumber}</p>
                          <p className="text-sm text-gray-500">
                            {quote?.date} {quote?.time}
                          </p>
                        </div>
                        <div>
                          <p className="text-lg font-bold text-blue-600 self-center">RM {quote?.totalPrice.toFixed(2)}</p>
                          {quote?.orderUid && (
                            <button
                              type="button"
                              onClick={() => history.push(`/store/orders/${quote?.orderUid}`)}
                              className="bg-primary-600 hover:bg-primary-700 text-gray-100 text-sm font-medium px-2 rounded"
                            >
                              View Order
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

StoreQuote.Create = StoreQuoteCreate;
StoreQuote.Detail = StoreQuoteDetail;

export default StoreQuote;
